import { Application, Controller } from "stimulus"

export default class CookiesNoticeController extends Controller {
  static targets = ["bannerCookiesContainer", "acceptAndRejectButtonsContainer", 
                    "saveButtonContainer", "searchForm", "analyticsCookies", 
                    "adPersonalizationCookies", "acceptAllCookiesButton",
                    "editPrivacyForm"]

  staticEditPrivacy() {
    fetch(this.editPrivacyFormTarget.action, {
      method: this.editPrivacyFormTarget.method,
    })
    .then(response => response.text())
    .then(jsCode => {
      const scriptElement = document.createElement("script");
      scriptElement.textContent = jsCode;
      document.body.appendChild(scriptElement);
    })
    .catch(error => {
      console.error("Error al enviar el formulario:", error);
    });
  }

  click() {
    setTimeout(() => {
      this.acceptAndRejectButtonsContainerTarget.classList.remove("d-flex")
      this.acceptAndRejectButtonsContainerTarget.classList.add("d-none")
      this.saveButtonContainerTarget.classList.remove("d-none")
    }, 0)
  }

  acceptAll() {
    setTimeout(() => {
      const user_consent = {
        "ad_storage": "granted",
        "analytics_storage": "granted",
        "ad_user_data": "granted",
        "ad_personalization": "granted"
      }

      this.closeAndSendConsent(user_consent)
    }, 0)
  }

  rejectAll() {
    setTimeout(() => {
      const user_consent = {
        "ad_storage": "denied",
        "analytics_storage": "denied",
        "ad_user_data": "denied",
        "ad_personalization": "denied"
      }

      this.closeAndSendConsent(user_consent)
    }, 0)
  }

  close() {
    setTimeout(() => {
      const user_consent = {
        "ad_storage": this.adPersonalizationCookiesTarget.checked ? "granted" : "denied",
        "analytics_storage": this.analyticsCookiesTarget.checked ? "granted" : "denied",
        "ad_user_data": this.adPersonalizationCookiesTarget.checked ? "granted" : "denied",
        "ad_personalization": this.adPersonalizationCookiesTarget.checked ? "granted" : "denied"
      }

      this.closeAndSendConsent(user_consent)
    }, 0)
  }

  closeAndSendConsent(user_consent) {
    this.bannerCookiesContainerTarget.classList.add("d-none")
    this.closeAndSendGoogleConsent(user_consent);
    this.closeAndSendBingConsent(user_consent);
    window.bsht_edit_privacy_settings_sheet?.desactivate();
  }

  closeAndSendGoogleConsent(user_consent) {
    if (typeof gtag2 !== 'undefined') {
      gtag2('consent', 'update', user_consent);
    }
  }

  closeAndSendBingConsent(user_consent) {
    if (typeof gtag2 !== 'undefined') {
      bingTag(user_consent["ad_storage"]);
    }
  }

  submitForm() {
    setTimeout(() => {
      if (this.optionsChecked()) {
        if(this.searchFormTarget.requestSubmit) {
          this.searchFormTarget.requestSubmit();
        }else {
          this.searchFormTarget.dispatchEvent(new CustomEvent('submit', {bubbles: true}))
        }
        this.close();
      }
    }, 0)
  }

  optionsChecked() {
    return document.querySelectorAll('input[type=radio]:checked').length == 2;
  }
}

const application = Application.start()
application.register("cookies-notice", CookiesNoticeController)
